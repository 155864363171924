import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from "styled-components";

/* Components */
import { colors } from "../../../../common/components/colors";
import { FontBody16, FontHeader18, FontBody18, FontTitle24 } from "../../../../common/components/fonts";
import { ButtonPrimary, ButtonInactive, ButtonSecondary } from "../../../../common/components/buttons";
import { images } from "../../../../common/components/images";

const Option = styled.div`
  border-radius: 30px;
  box-shadow: 0 10px 25px 0 rgb(0 0 0 / 10%);
  padding: 30px 30px 50px 30px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 33%;
  max-width: 350px;
  @media screen and (max-width: 1260px) {
    width: 100%;
  }
`;

const Plan = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const PlanDetails = styled(FontHeader18)`
    margin-bottom: 20px;
`;

const List = styled.ul`
  margin: 0;
  padding: 15px 0 15px 18px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.border100};
`;

const CertImage = styled.img`
height: 200px;
width: 200px;
align-self: center;
`;

const Price = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonWide = styled.div`
  & > div {
    width: calc(100% - 46px);
    text-align: center;
  }
`;


class FirmPlanWomen extends React.Component {
    state = { "isLoading": true, "currentPlanId": null, "show": null, "title": "" };

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const w = this.props.certWomen;
        const n = this.props.certNextGen;

        let show = null
        // "buyCertificateFirstTime & buyCertificateRenew https://pay.trustwillow.com/b/5kAaG42oebQ51Py00d
        // "noPurchaseUntilOtherCertIsDone"
        // "noPurchaseUntilExpirationDate"

        let title = "Advisor for Women™ Certificate"

        if(n.status === "inProgress" && w.status !== "completed") {
            show = "noPurchaseUntilOtherCertIsDone"
        } else if(w.status === "inProgress" || w.status === "completed") {
            if(w.renewalDate === null) {
                show = "buyCertificateRenew"
            } else {
                show = w.isExpired ? "buyCertificateRenew" : "noPurchaseUntilExpirationDate"
            }
        } else if(w.status === null) {
            show = "buyCertificateFirstTime"
        }

        await this.setState({
            // "currentPlanId": currentPlanId,
            "title": title,
            "show": show,
            "isLoading": false
        });
    };


    render() {
        return (
            <Option>
                <FontTitle24 style={{ textAlign: "center" }}>{this.state.title}</FontTitle24>
                <CertImage src={images.womenBadge} />
                <FontBody16>Demonstrate that you are a top advisor who is committed to supporting the unique needs of women clients.</FontBody16>
                <Plan>
                    <PlanDetails>Details:</PlanDetails>
                    <List>
                        <li><FontBody16>Digital Certificate Badge</FontBody16></li>
                        <li><FontBody16>Quarterly practice management & best practice sharing sessions</FontBody16></li>
                        <li><FontBody16>Additional training & networking opportunities</FontBody16></li>
                    </List>
                </Plan>
                {/*{this.state.show === "buyCertificateFirstTime" &&*/}
                    <>
                        <Price><FontHeader18>$800</FontHeader18><FontBody18>/yr</FontBody18></Price>
                        <ButtonWide onClick={() => {window.location.href = 'https://pay.trustwillow.com/b/5kAaG42oebQ51Py00d?client_reference_id='+this.props.userId}}>
                            <ButtonPrimary label={"Get Started"} canSubmit={false} />
                        </ButtonWide>
                    </>
                {/*}*/}
                {this.state.show === "buyCertificateRenew" &&
                    <>
                        <Price><FontHeader18>$800</FontHeader18><FontBody18>/yr</FontBody18></Price>
                        <ButtonWide onClick={() => {window.location.href = 'https://pay.trustwillow.com/b/5kAaG42oebQ51Py00d?client_reference_id='+this.props.userId}}>
                            <ButtonPrimary label={"Renew Certificate"} canSubmit={false} />
                        </ButtonWide>
                        <FontBody16 style={{color: colors.alert100}}>Expired: {this.props.certWomen.renewalDate}</FontBody16>
                    </>
                }
                {this.state.show === "noPurchaseUntilOtherCertIsDone" &&
                    <FontBody16>Finish your other certificate to purchase this one</FontBody16>
                }
                {this.state.show === "noPurchaseUntilExpirationDate" &&
                    <>
                        <ButtonWide>
                            <ButtonInactive label={"Active Certificate"} canSubmit={false} />
                        </ButtonWide>
                        <FontBody16>Active through: {this.props.certWomen.renewalDate}</FontBody16>
                    </>
                }
            </Option>
        );
    }
}

const mapStateToProps = state => ({
    userId: state.common.user.get("userId"),
});

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmPlanWomen));
