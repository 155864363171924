import React from 'react';
import { Switch } from 'react-router-dom';

/* Containers */
import SecureRoute from '../../../routes/containers/routesecure';

/* Pages - Enterprise */
import EnterpriseProspects from '../../../enterprise/pages/prospects';
import EnterpriseClients from '../../../enterprise/clients/page';
import EnterpriseClientsDetail from '../../../enterprise/pages/clientsdetail';
import EnterpriseListings from '../../../enterprise/listings/page';
import EnterpriseMeetings from '../../../enterprise/meetings/page';
import EnterpriseSettings from '../../../enterprise/settings/page';
import EnterpriseMyTeam from '../../../enterprise/team/page';
import EnterpriseExperts from '../../../enterprise/experts/page';
import EnterpriseResources from '../../../enterprise/resources/page';
import EnterpriseContacts from '../../../enterprise/pages/contacts';
import EnterpriseMyShop from '../../../enterprise/shop/page';
// import EnterpriseMyProfile from '../../../enterprise/profile/page';
import EnterpriseAvailability from '../../../enterprise/availability/page';
import EnterpriseSharedVault from '../../../enterprise/vault/page';
import EnterpriseBilling from '../../../enterprise/billing/page';
import EnterprisePartners from '../../../enterprise/partnerships/page';
import EnterpriseHome from '../../../enterprise/homepage/page';
// import EnterpriseForms from '../../../enterprise/forms/page';
import EnterpriseCurriculum from '../../../enterprise/curriculum/page';
import Forum from '../../../enterprise/forum/page';
import EnterpriseFirmDashboard from '../../../enterprise/firm/dashboard/page';
import EnterpriseFirmSettings from '../../../enterprise/firm/settings/page';
// import EnterpriseFirmResources from '../../../enterprise/firm/resources/page';
// import EnterpriseFirmCoaches from '../../../enterprise/firm/coaches/page';
// import EnterpriseFirmClients from '../../../enterprise/firm/clients/page';
import EnterpriseFirmRefer from '../../../enterprise/firm/referral/page';
// import EnterpriseFirmTrainings from '../../../enterprise/firm/trainings/page';
import EnterpriseFirmReports from '../../../enterprise/firm/reports/page';
import EnterpriseFirmTasks from '../../../enterprise/firm/tasks/page';
import EnterpriseFirmPlans from '../../../enterprise/firm/plans/page';
import EnterpriseFirmMarketing from '../../../enterprise/firm/marketing/page';
import FirmCertification from "../../../enterprise/firm/certification/page";
// import CertManage from "../../../enterprise/firm/certification/containers/manage";
// import CertBenefits from "../../../enterprise/firm/certification/containers/benefits";
import FirmRoundtables from "../../../enterprise/firm/roundtables/page";
import {FirmElectiveTrainings} from "../../../enterprise/firm/certification/containers/electivetrainings";

export const enterpriseRoutes = [
    '/contacts',
    '/contacts/previous',
    '/contacts/create',
    '/prospects',
    '/prospects/create',
    '/clients',
    '/clients/previous',
    '/clients/create',
    '/contact/activity',
    '/contact/notes',
    '/contact/tasks',
    '/contact/emails',
    '/contact/sms',
    '/contact/chats',
    '/contact/meetings',
    '/contact/messages',
    '/contact/finances',
    '/contact/instructions',
    '/contact/vault',
    '/contact/edit',
    '/home',
    '/meetings',
    '/meetings/create',
    '/meetings/create/call',
    '/meetings/create/webinar',
    '/meetings/edit/webinar',
    '/meetings/details',
    '/meetings/page',
    '/vaults',
    '/vaults/drive',
    '/vaults/create',
    '/shop',
    '/shop/products',
    '/shop/portfolio',
    '/shop/portfolio/create',
    '/shop/portfolio/edit',
    '/shop/testimonials',
    '/shop/testimonials/create',
    '/shop/testimonials/edit',
    '/shop/faqs',
    '/shop/faqs/create',
    '/shop/faqs/edit',
    '/shop/about',
    '/shop/about/experience/create',
    '/shop/about/experience/edit',
    '/shop/about/education/create',
    '/shop/about/education/edit',
    '/shop/about/license/create',
    '/shop/about/license/edit',
    '/settings/profile',
    '/settings/profile/about',
    '/settings/profile/about/experience/create',
    '/settings/profile/about/experience/edit',
    '/settings/profile/about/education/create',
    '/settings/profile/about/education/edit',
    '/settings/profile/about/license/create',
    '/settings/profile/about/license/edit',
    '/availability',
    '/team',
    '/team/member',
    '/experts',
    '/resources',
    '/products',
    '/products/create',
    '/products/edit',
    '/settings/basic-info',
    '/settings/security',
    '/settings/branding',
    '/settings/coaching',
    '/settings/users',
    '/settings/users/create',
    '/settings/users/edit',
    '/settings/prospects',
    '/settings/availability/calendar',
    '/settings/availability/appointments',
    '/settings/availability/appointments/create',
    '/settings/availability/appointments/edit',
    '/settings/availability/calendar/set',
    '/settings/billing',
    '/partners',
    '/partners/employees',
    '/partners/employees/member',
    '/partners/employees/clients',
    '/partners/support',
    '/partners/support/member',
    '/firms',
    '/firms/employees',
    '/firms/employees/member',
    '/firms/experts',
    '/firms/experts/member',
    '/firms/experts/clients',
    '/firms/support',
    '/firms/support/member',
    '/curriculum',
    '/curriculum/create',
    '/curriculum/edit',
    '/curriculum/preview',
    '/community',
    '/community/posts/create',
    '/community/posts/edit',
    '/community/posts/delete',
    // '/community/posts/:id',
    '/community/posts/post',
    // '/community/:category',
    '/community/category',
    // '/community/:category/:subcategory',
    '/community/category/subcategory',
    '/dashboard',
    '/my-settings',
    // '/my-settings/company',
    // '/my-settings/users',
    // '/my-settings/users/create',
    // '/my-settings/users/edit',
    // '/my-settings/billing',
    '/my-certificates/my-coaches',
    // '/my-certificates/my-coaches/search',
    '/my-certificates/my-coaches/browse',
    '/my-certificates/my-coaches/:id',
    '/my-certificates/my-coaches/:id/documents',
    '/my-certificates/my-coaches/:id/profile',
    '/my-certificates/my-coaches/:id/appointment',
    '/my-certificates/my-coaches/:id/messages',
    // '/my-certificates/my-coaches/:id/add-appointments',
    // '/my-certificates/my-coaches/:id/add-appointments/checkout',
    // '/my-certificates/my-coaches/add-appointments',
    // '/my-certificates/my-coaches/add-appointments/checkout',
    '/my-growth',
    // '/my-growth/prospects',
    // '/my-growth/articles',
    // '/my-growth/articles/:id/edit',
    // '/my-growth/articles/:id/edit-compliance',
    // '/my-growth/articles/:id/edit-published',
    // '/my-growth/articles/:id/feedback',
    '/my-growth/profile',
    // '/my-growth/profile/edit-intro',
    // '/my-growth/profile/edit-profile',
    // '/my-appointments',
    // '/my-resources',
    '/my-certificates/my-trainings',
    '/elective-trainings',
    // '/my-trainings/live',
    // '/my-trainings/browse',
    '/my-certificates/my-trainings/:id',
    '/elective-trainings/:id',
    // '/my-trainings/:id/register',
    // '/my-trainings/:id/requested',
    // '/my-trainings/add-trainings',
    // '/my-trainings/add-trainings/checkout',
    '/my-tasks',
    '/my-tasks/:id',
    '/my-tasks/course/:id/video',
    '/my-tasks/course/:id/quiz',
    '/my-tasks/course/:id/failed',
    '/my-tasks/course/:id/passed',
    '/my-tasks/course/:id/completed',
    // '/my-trainings/:id/checkout',
    // '/my-trainings/:id/registration-completed',
    '/upgrade',
    '/refer',
    // '/my-clients',
    // '/my-clients/previous',
    // '/my-clients/create',
    // '/my-clients/:id',
    // '/my-clients/:id/notes',
    // '/my-clients/:id/settings',
    // '/my-clients/:id/add-appointments',
    // '/my-clients/:id/add-appointments/checkout',
    '/reports',
    '/upgrade',
    // '/upgrade/checkout',
    '/my-certificates',
    // '/my-certificates/manage',
    // '/my-certificates/benefits',
    '/my-certificates/activate',
    '/my-certificates/activate/checkout',
    '/my-certificates/renew',
    '/my-certificates/renew/checkout',
    '/my-certificates/profile',
    '/my-certificates/profile/calendar',
    '/my-roundtables',
    '/my-roundtables/browse',
    '/my-roundtables/:id',
];

const RoutesTrustWillowEnterprise = () => (
    <Switch>
        <SecureRoute exact path={'/contacts'} component={EnterpriseContacts}/>
        <SecureRoute exact path={'/contacts/previous'} component={EnterpriseContacts}/>
        <SecureRoute exact path={'/contacts/create'} component={EnterpriseContacts}/>
        <SecureRoute exact path={'/prospects'} component={EnterpriseProspects}/>
        <SecureRoute exact path={'/prospects/create'} component={EnterpriseProspects}/>
        <SecureRoute exact path={'/clients'} component={EnterpriseClients}/>
        <SecureRoute exact path={'/clients/previous'} component={EnterpriseClients}/>
        <SecureRoute exact path={'/clients/create'} component={EnterpriseClients}/>
        <SecureRoute exact path={'/contact/activity'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/notes'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/tasks'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/emails'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/sms'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/chats'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/meetings'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/messages'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/finances'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/instructions'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/vault'} component={EnterpriseClientsDetail}/>
        <SecureRoute exact path={'/contact/edit'} component={EnterpriseContacts}/>
        <SecureRoute exact path={'/home'} component={EnterpriseHome} />
        <SecureRoute exact path={'/meetings'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/meetings/create'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/meetings/create/call'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/meetings/create/webinar'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/meetings/edit/webinar'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/meetings/details'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/meetings/page'} component={EnterpriseMeetings}/>
        <SecureRoute exact path={'/vaults'} component={EnterpriseSharedVault}/>
        <SecureRoute exact path={'/vaults/drive'} component={EnterpriseSharedVault}/>
        <SecureRoute exact path={'/vaults/create'} component={EnterpriseSharedVault}/>
        <SecureRoute exact path={'/shop'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/products'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/portfolio'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/portfolio/create'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/portfolio/edit'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/testimonials'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/testimonials/create'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/testimonials/edit'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/faqs'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/faqs/create'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/faqs/edit'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about/experience/create'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about/experience/edit'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about/education/create'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about/education/edit'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about/license/create'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/shop/about/license/edit'} component={EnterpriseMyShop}/>
        <SecureRoute exact path={'/settings/profile'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/profile/about'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/profile/about/experience/create'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/profile/about/experience/edit'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/profile/about/education/create'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/profile/about/education/edit'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/profile/about/license/create'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/profile/about/license/edit'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/availability'} component={EnterpriseAvailability}/>
        <SecureRoute exact path={'/team'} component={EnterpriseMyTeam}/>
        <SecureRoute exact path={'/team/member'} component={EnterpriseMyTeam}/>
        <SecureRoute exact path={'/experts'} component={EnterpriseExperts}/>
        <SecureRoute exact path={'/resources'} component={EnterpriseResources}/>
        <SecureRoute exact path={'/products'} component={EnterpriseListings}/>
        <SecureRoute exact path={'/products/create'} component={EnterpriseListings}/>
        <SecureRoute exact path={'/products/edit'} component={EnterpriseListings}/>
        <SecureRoute exact path={'/settings/basic-info'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/security'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/branding'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/coaching'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/users'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/users/create'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/users/edit'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/prospects'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/availability/calendar'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/availability/appointments'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/availability/appointments/create'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/availability/appointments/edit'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/availability/calendar/set'} component={EnterpriseSettings}/>
        <SecureRoute exact path={'/settings/billing'} component={EnterpriseBilling}/>
        <SecureRoute exact path={'/partners'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/partners/employees'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/partners/employees/member'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/partners/employees/clients'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/partners/support'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/partners/support/member'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/employees'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/employees/member'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/experts'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/experts/member'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/experts/clients'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/support'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/firms/support/member'} component={EnterprisePartners}/>
        <SecureRoute exact path={'/curriculum'} component={EnterpriseCurriculum}/>
        <SecureRoute exact path={'/curriculum/create'} component={EnterpriseCurriculum}/>
        <SecureRoute exact path={'/curriculum/edit'} component={EnterpriseCurriculum}/>
        <SecureRoute exact path={'/curriculum/preview'} component={EnterpriseCurriculum}/>
        <SecureRoute exact path={'/community'} component={Forum}/>
        <SecureRoute exact path={'/community/category'} component={Forum}/>
        <SecureRoute exact path={'/community/category/subcategory'} component={Forum}/>
        <SecureRoute exact path={'/community/posts/post'} component={Forum}/>
        <SecureRoute exact path={'/community/posts/create'} component={Forum}/>
        <SecureRoute exact path={'/dashboard'} component={EnterpriseFirmDashboard} />
        <SecureRoute exact path={'/my-settings'} component={EnterpriseFirmSettings}/>
        {/*<SecureRoute exact path={'/my-settings/company'} component={EnterpriseFirmSettings}/>*/}
        {/*<SecureRoute exact path={'/my-settings/users'} component={EnterpriseFirmSettings}/>*/}
        {/*<SecureRoute exact path={'/my-settings/users/create'} component={EnterpriseFirmSettings}/>*/}
        {/*<SecureRoute exact path={'/my-settings/users/edit'} component={EnterpriseFirmSettings}/>*/}
        {/*<SecureRoute exact path={'/my-settings/billing'} component={EnterpriseFirmSettings}/>*/}
        <SecureRoute exact path={'/my-certificates/my-coaches'} component={FirmCertification}/>
        {/*<SecureRoute exact path={'/my-certificates/my-coaches/search'} component={FirmCertification}/>*/}
        <SecureRoute exact path={'/my-certificates/my-coaches/browse'} component={FirmCertification}/>
        <SecureRoute exact path={'/my-certificates/my-coaches/:id'} component={FirmCertification}/>
        <SecureRoute exact path={'/my-certificates/my-coaches/:id/documents'} component={FirmCertification}/>
        <SecureRoute exact path={'/my-certificates/my-coaches/:id/profile'} component={FirmCertification}/>
        <SecureRoute exact path={'/my-certificates/my-coaches/:id/messages'} component={FirmCertification}/>
        <SecureRoute exact path={'/my-certificates/my-coaches/:id/appointment'} component={FirmCertification}/>
        {/*<SecureRoute exact path={'/my-certificates/my-coaches/:id/add-appointments'} component={FirmCertification}/>*/}
        {/*<SecureRoute exact path={'/my-certificates/my-coaches/:id/add-appointments/checkout'} component={FirmCertification}/>*/}
        {/*<SecureRoute exact path={'my-certificates//my-coaches/add-appointments'} component={FirmCertification}/>*/}
        {/*<SecureRoute exact path={'/my-certificates/my-coaches/add-appointments/checkout'} component={FirmCertification}/>*/}
        <SecureRoute exact path={'/my-certificates/my-trainings'} component={FirmCertification}/>
        <SecureRoute exact path={'/elective-trainings'} component={FirmElectiveTrainings}/>
        <SecureRoute exact path={'/elective-trainings/:id'} component={FirmCertification}/>
        {/*<SecureRoute exact path={'/my-trainings/live'} component={EnterpriseFirmTrainings}/>*/}
        {/*<SecureRoute exact path={'/my-trainings/browse'} component={EnterpriseFirmTrainings}/>*/}
        <SecureRoute exact path={'/my-certificates/my-trainings/:id'} component={FirmCertification}/>
        {/*<SecureRoute exact path={'/my-trainings/:id/register'} component={EnterpriseFirmTrainings}/>*/}
        {/*<SecureRoute exact path={'/my-trainings/:id/requested'} component={EnterpriseFirmTrainings}/>*/}
        {/*<SecureRoute exact path={'/my-trainings/add-trainings'} component={EnterpriseFirmTrainings}/>*/}
        {/*<SecureRoute exact path={'/my-trainings/add-trainings/checkout'} component={EnterpriseFirmTrainings}/>*/}
        <SecureRoute exact path={'/my-tasks'} component={EnterpriseFirmTasks}/>
        <SecureRoute exact path={'/my-tasks/:id'} component={EnterpriseFirmTasks}/>
        <SecureRoute exact path={'/my-tasks/course/:id/video'} component={EnterpriseFirmTasks}/>
        <SecureRoute exact path={'/my-tasks/course/:id/quiz'} component={EnterpriseFirmTasks}/>
        <SecureRoute exact path={'/my-tasks/course/:id/failed'} component={EnterpriseFirmTasks}/>
        <SecureRoute exact path={'/my-tasks/course/:id/passed'} component={EnterpriseFirmTasks}/>
        <SecureRoute exact path={'/my-tasks/course/:id/completed'} component={EnterpriseFirmTasks}/>
        {/*<SecureRoute exact path={'/my-trainings/:id/checkout'} component={EnterpriseFirmTrainings}/>*/}
        {/*<SecureRoute exact path={'/my-trainings/:id/registration-completed'} component={EnterpriseFirmTrainings}/>*/}
        {/*<SecureRoute exact path={'/my-resources'} component={EnterpriseFirmResources}/>*/}
        <SecureRoute exact path={'/refer'} component={EnterpriseFirmRefer}/>
        {/*<SecureRoute exact path={'/my-clients'} component={EnterpriseFirmClients}/>*/}
        {/*<SecureRoute exact path={'/my-clients/previous'} component={EnterpriseFirmClients}/>*/}
        {/*<SecureRoute exact path={'/my-clients/create'} component={EnterpriseFirmClients}/>*/}
        {/*<SecureRoute exact path={'/my-clients/:id'} component={EnterpriseFirmClients}/>*/}
        {/*<SecureRoute exact path={'/my-clients/:id/notes'} component={EnterpriseFirmClients}/>*/}
        {/*<SecureRoute exact path={'/my-clients/:id/settings'} component={EnterpriseFirmClients}/>*/}
        {/*<SecureRoute exact path={'/my-clients/:id/add-appointments'} component={EnterpriseFirmClients}/>*/}
        {/*<SecureRoute exact path={'/my-clients/:id/add-appointments/checkout'} component={EnterpriseFirmClients}/>*/}
        <SecureRoute exact path={'/my-growth'} component={EnterpriseFirmMarketing}/>
        <SecureRoute exact path={'/my-growth/profile'} component={EnterpriseFirmMarketing}/>
        {/*<SecureRoute exact path={'/my-growth/profile/edit-intro'} component={EnterpriseFirmMarketing}/>*/}
        {/*<SecureRoute exact path={'/my-growth/profile/edit-profile'} component={EnterpriseFirmMarketing}/>*/}
        {/*<SecureRoute exact path={'/my-growth/articles'} component={EnterpriseFirmMarketing}/>*/}
        {/*<SecureRoute exact path={'/my-growth/articles/:id/edit'} component={EnterpriseFirmMarketing}/>*/}
        {/*<SecureRoute exact path={'/my-growth/articles/:id/edit-compliance'} component={EnterpriseFirmMarketing}/>*/}
        {/*<SecureRoute exact path={'/my-growth/articles/:id/edit-published'} component={EnterpriseFirmMarketing}/>*/}
        {/*<SecureRoute exact path={'/my-growth/articles/:id/feedback'} component={EnterpriseFirmMarketing}/>*/}
        {/*<SecureRoute exact path={'/my-growth/prospects'} component={EnterpriseFirmMarketing}/>*/}
        <SecureRoute exact path={'/reports'} component={EnterpriseFirmReports}/>
        <SecureRoute exact path={'/upgrade'} component={EnterpriseFirmPlans}/>
        {/*<SecureRoute exact path={'/upgrade/checkout'} component={EnterpriseFirmPlans}/>*/}
        <SecureRoute exact path={'/my-certificates'} component={FirmCertification}/>
        {/*<SecureRoute exact path={'/my-certificates/manage'} component={FirmCertification} />*/}
        {/*<SecureRoute exact path={'/my-certificates/benefits'} component={FirmCertification} />*/}
        {/*<SecureRoute exact path={'/my-certificates/activate'} component={FirmCertification} />*/}
        {/*<SecureRoute exact path={'/my-certificates/activate/checkout'} component={FirmCertification} />*/}
        {/*<SecureRoute exact path={'/my-certificates/renew'} component={FirmCertification} />*/}
        {/*<SecureRoute exact path={'/my-certificates/renew/checkout'} component={FirmCertification} />*/}
        <SecureRoute exact path={'/my-certificates/profile'} component={FirmCertification} />
        {/*<SecureRoute exact path={'/my-certificates/profile/calendar'} component={FirmCertification} />*/}
        <SecureRoute exact path={'/my-roundtables'} component={FirmRoundtables} />
        <SecureRoute exact path={'/my-roundtables/browse'} component={FirmRoundtables} />
        <SecureRoute exact path={'/my-roundtables/:id'} component={FirmRoundtables} />


    </Switch>
);

export default RoutesTrustWillowEnterprise;
