import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

/* Components */
import {FontBody16} from '../../../../common/components/fonts';
import {ButtonInactive, ButtonTertiary} from "../../../../common/components/buttons";
import {TextInputWhite} from "../../../../common/components/inputs";
import {DeleteOutlined} from "@ant-design/icons";
import {colors} from "../../../../common/components/colors";

const Btn = styled.div`
    width: fit-content;
    cursor: pointer;
`;
const Pill = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: ${colors.border30};
    margin-bottom: 10px;
    width: fit-content;
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
`;

export default function InputArray(props) {
    const save = props.save;
    const initData = props.initData;
    const title = props.title;
    const [array, setArray] = useState([])
    const [arrayItem, setArrayItem] = useState("")
    const [hasChanged, setHasChange] = useState(false)

    useEffect(() => {
        if(initData != null && initData.length > 0) {
            setArray(initData)
        }
    }, [initData])

    const addItem = () => {
        const item = arrayItem;
        if(item !== undefined && item !== '') {
            const newArray = [...array, item];
            setArray(newArray)
            save(newArray)
            setArrayItem("")
        }
    }

    const deleteItem = (item) => {
        const remainingItems = array.filter(a => {return(a !== item)});
        setArray(remainingItems)
        save(remainingItems)
    }

    const changeInput = (e) => {
        const item = e.target.value;
        setArrayItem(item)
        setHasChange(item !== null && item !== undefined && item !== '')
    }

    return(
        <>
            <TextInputWhite
                title={title}
                valid={true}
                id={title.toLowerCase()}
                onChange={changeInput}
                value={arrayItem}
            />
            {hasChanged
                ? <div onClick={addItem}><ButtonTertiary label={"Add"} canSubmit={false} /></div>
                : <ButtonInactive label={"Add"} canSubmit={false} />
            }
            <br />
            {array != null && array.length > 0 &&
                <Row>
                    {array.map((e, index) => (
                        <Pill key={index}>
                            <FontBody16>&nbsp;{e}</FontBody16>
                            <Btn onClick={() => {return deleteItem(e)}}><DeleteOutlined /></Btn>
                        </Pill>
                    ))}
                </Row>
            }
        </>
    )
}