import { axiosReq } from "../../../../common/utils/axios";

/* DevData */
import {
    // devEnterpriseBillingHistoryData,
    profileDetailsData,
    // devLegalConfirmData,
    // devLegalConfirmedDate,
    // devRenewAfterLegalEnds,
    // devRenewBeforeLegalEnds
} from "../static/devdata";
// import {devFailedTask} from "../../tasks/static/devdata";
// import {devRecurringAvailabilityData,} from "../../../settings/recurring/static/devdata";

// export const tryGetPurchaseHistoryDate = () => {
//     return async dispatch => {
//         return axiosReq('apiv1/consumer/purchase/get-purchase-history', 'GET',{},devRenewBeforeLegalEnds)
//     }
// }


export const trySaveDirectoryProfile = (profileDetails) => {
    return async dispatch => {
        return axiosReq('apiv1/coach/settings/advisor-profile', 'PUT',profileDetails, profileDetailsData)

    }
}

export const tryDirectoryProfileDetails = () => {
    return async dispatch => {
        return axiosReq('apiv1/coach/settings/advisor-profile', 'GET',{}, profileDetailsData)

    }
}

// export const tryGetAttestationDetails = () => {
//     return async dispatch => {
//         return axiosReq('apiv1/consumer/courses/completed-attestations','GET',{},devFailedTask)
//     }
// }

// export const tryGetScheduleComplete = () => {
//     return async dispatch => {
//         const sync = await axiosReq('apiv1/coach/settings/calendar-preferences', "GET", {}, devRecurringAvailabilityData);
//         return sync.availability_calendar_update_method;
//
//     }
// }

// export const tryGetCertificationCompleted = () => {
//     return async dispatch => {
//         return axiosReq('apiv1/coach/certification/completed', 'GET',{}, [])
//
//     }
// }

// export const tryGetLegalConfirmation = () => {
//     return async dispatch => {
//         return axiosReq('apiv1/coach/settings/legal-confirmation', 'GET',{}, devLegalConfirmedDate)
//
//     }
// }

// export const tryUpdateLegalConfirmation = (data) => {
//     return async dispatch => {
//         return axiosReq('apiv1/coach/settings/legal-confirmation','PUT',data, devLegalConfirmedDate)
//     }
// }
