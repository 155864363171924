export const profileDetailsData = {
    "id": 4795, // 0
    "firstName": "Mary", // ""
    "lastName": "o", // ""
    "photos": [
        "https://caycepollard-demo.carrd.co/assets/images/image01.jpg?v68863967512051",
        "https://19866b817367646c.demo.carrd.co/assets/images/bg-7d818abb.jpg?v=85660041",
        "https://19866b817367646c.demo.carrd.co/assets/images/bg-3ced4ac3.jpg?v=85660041",
    ], // []
    "intro": {
        "video": "link",
        "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type spe\n" +
            "\n" +
            "\n" +
            "\n" +
            "cimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.\n" +
            "\n" +
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lore\n" +
            "\n" +
            "\n" +
            "m Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s w\n" +
            "\n" +
            "\n" +
            "ith the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "animal" :"Dog",
        "hobby": "Who has time for hobbies?",
        "birthOrder": "Only Child",
        "zodiac": "Cancer",
        "mbti": "ISTP",}, // {video: "", description: ""}
    "expertise": ["Financial planning", "Portfolio management"], // []
    "specialities": [], // []
    "services": [], // []
    "disclosure": "Here is a long text that the advisor needs to put on the page to cover disclosures from their compliance department. We will have a standard and then the advisor needs to customize it to them.", // null
    "logos": ["https://res.cloudinary.com/blz-lilyriche/image/upload/v1681740308/e1octzlxn4o65msy8mdw.png"], // []
    "firmSec": "333", // null
    "advisorSec": "333", // null
    "firmName": "name", // null
    "firmBio": "Here is a long paragraph about the firm. It is the coolest firm ever. I really want to work with them.", // null
    "firmLogo": "link",// null
    "firmAddress": {"address": "Building name, building number, state, city, zip"},// {}
}
