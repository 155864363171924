import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';

/* Components */
import {colors} from '../../../../common/components/colors';
import {FontBody16, FontBody24, FontSubtitle40} from '../../../../common/components/fonts';
import {images} from "../../../../common/components/images";
import FirmMarketingNav from '../components/nav';

/* Containers */
// import FirmMarketingOverview from "./overview";
// import FirmMarketingProfile from "./profile";
// import FirmMarketingCompliance from "./compliance";
import FirmMarketingProspects from "./prospects";
import FirmMarketingCriteria from "./criteria";
import FirmMarketingProfile from "./marketingprofile";
import {
    CheckCircleFilled,
    CheckCircleOutlined,
    CheckCircleTwoTone,
    CloseCircleFilled,
    CloseCircleOutlined, CloseCircleTwoTone
} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {tryAuthOnly} from "../../../../routes/middleware/auth";
import {tryPublishDirectoryProfile} from "../../certification/middleware/membership";

export const TopSegment = styled.div`
    background-image: url(${images.firmBackgroundTop});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    left: -60px;
    right: 0;
    top: -90px;
    bottom: 0;
    padding: 90px 60px 0 60px;
    width: 100%;
    @media screen and (max-width: 748px) {
        left: -30px;
        right: -30px;
        top: -30px;
        bottom: 0;
        padding: 30px 30px 0 30px;
    }
    @media screen and (max-width: 450px) {
        padding: 30px 15px 0 30px;
    }
`;
const Subtitle = styled(FontBody24)`
    margin: 30px 0 0 0;
    min-height: 108px;
`;
const Upgrade = styled.div`
    display: inline-block;
    text-decoration: underline;
    font-weight: bold;
    color: ${colors.primary100};
    cursor: pointer;
`;
const Foreground = styled.div`    
    position: relative;
    z-index: 2;
`;
const Background = styled.div`    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.white};
    z-index: 1;
`;

const PublishText = styled(FontBody16)`
  display:flex;
  gap: 10px;
  align-items: center;
  justify-content: left;
  margin-top: 0;
`;
export default function FirmMarketingStructure(){
    const directoryListings = useSelector((state) => state.common.user.get("directoryListings"))
    const [isPublished, setPublished] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        init();
    },[isPublished])
    const init = () => {
            setPublished(directoryListings?.get("clientAcquisitionStatus") === 'Active')
    }

    const handlePublish = async () => {
        await dispatch(tryPublishDirectoryProfile())
        await dispatch(tryAuthOnly(null, false, true))
        setPublished(true)
    }
        return (
            <>
                <Foreground>
                    <TopSegment>
                        <FontSubtitle40 spaced={true}>CLIENT ACQUISITION</FontSubtitle40>
                        {/*{this.state.cannotAccessAllTrainings*/}
                        {/*    ? <Subtitle>TBD. You have [{this.props.pms_sessions}] TBD. <Upgrade onClick={this.addMoreTrainings}><b>Add more TBD.</b></Upgrade></Subtitle>*/}
                        {/*    : */}
                        <Subtitle>
                            {isPublished ? <CheckCircleTwoTone style={{color: colors.stoplightGo100}} twoToneColor={colors.stoplightGo100}/> : <CloseCircleTwoTone style={{color: colors.stoplightStop100}} twoToneColor={colors.stoplightStop100}/> }
                               &nbsp; {isPublished ?
                                    "Your profile is published and is being presented to investors who would be a great match for you."
                                    : "Your profile is not published. Navigate to the Marketing Profile tab, set up your profile, and then click publish to start being presented to investors who would be a great match for you."}
                        </Subtitle>
                        {/*}*/}


                        <FirmMarketingNav />
                    </TopSegment>
                    {/*{(location.pathname === "/my-growth") && <FirmMarketingOverview />}*/}
                    {(location.pathname === "/my-growth/profile") && <FirmMarketingCriteria />}
                    {/*{(location.pathname === "/my-growth/articles") && <FirmMarketingCompliance />}*/}
                    {(location.pathname === "/my-growth") && <FirmMarketingProspects />}
                    {(location.pathname === "/my-growth/marketing-profile") && <FirmMarketingProfile setPublish={handlePublish}/>}
                </Foreground>
                <Background />
            </>
        )

}

