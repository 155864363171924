import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {Map} from 'immutable';

/* Components */
import {FontHeader18, FontSubtitle24} from "../../../../common/components/fonts";
import { colors } from "../../../../common/components/colors";
import { images } from "../../../../common/components/images";
import { LogoLoading } from "../../../../common/components/loading";
import CompletedCertifications from './certificationcompleted';
import InProgressCertification from './certificationprogress';

/* Middleware */
import { certificationValidation } from "../../certification/middleware/certificationValidation";

//#region Styled components
const LogoCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & img {
    max-width: 150px;
    max-height: 150px;
  }
`;
const DashboardContainer = styled.div`
  border-radius: 30px;
  padding: 30px;
  border-color: ${colors.action30};
  background-color: ${colors.white};
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 19;
  box-shadow: 0 10px 25px 0 ${colors.action30};
`;
// border-radius: 30px 30px 0 0;
const Header = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;
const TopHeader = styled.div`
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
const BottomHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 10px;
`;
const HeaderTab = styled.div`
  border-radius: 100px;
  background-color: ${(props) => (props.selected ? "#ece5da" : colors.white)};
  padding: 5px 15px;
  cursor: pointer;
`;
const WidgetContent = styled.div`
  margin-top: 20px;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  align-self: center;
  justify-self: center;
  @media only screen and (max-width: 735px) {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  }
`;

const DashboardCertificationWidget = forwardRef((props, ref) => {
  const directoryListings = useSelector((state) => (state.common.user.get("directoryListings")));
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [certificationStatus, setCertificationStatus] = useState({
    currentProgress: null,
    completedCertifications: [],
    isLoading: true
  });

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const validation = await dispatch(certificationValidation());
    setSelectedTab(validation.currentProgress === null ? 1 : 0)
    setCertificationStatus({...validation, "isLoading": false});
  };

  if(directoryListings !== Map() && directoryListings.get("practiceManagementUntil") !== null) {
    return null
  }

  return (
    <DashboardContainer ref={ref}>
      <Header>

        <TopHeader>
          <Icon style={{ marginTop: "4px" }} image={images.certificateIcon} />
          <FontSubtitle24>CERTIFICATES</FontSubtitle24>
        </TopHeader>

        <BottomHeader>
          <HeaderTab selected={selectedTab == 0} onClick={() => setSelectedTab(0)}>
            <FontHeader18>In progress</FontHeader18>
          </HeaderTab>
          <HeaderTab selected={selectedTab == 1} onClick={() => setSelectedTab(1)}>
            <FontHeader18>Completed</FontHeader18>
          </HeaderTab>
        </BottomHeader>

      </Header>

      {certificationStatus.isLoading
        ? (<WidgetContent><LogoCenter><LogoLoading /></LogoCenter></WidgetContent>)
        : <>
            {selectedTab == 0 && (
                <InProgressCertification currentProgress={certificationStatus.currentProgress}/>
            )}
            {selectedTab == 1 && (
                <CompletedCertifications completedCertifications={certificationStatus.completedCertifications}/>
            )}
          </>
      }

    </DashboardContainer>
  );
});

export default DashboardCertificationWidget;