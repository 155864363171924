import { tryAuthOnly } from "../../../../routes/middleware/auth";

const trainings = async (progress) => {

    const training = progress.get("details").get("trainingStep");

    if (training.get("completedTrainings").size === 0) {
        return ({
            mainTrainingsCompleted: false,
            mainTrainingCompletedCoaching: false,
            mainTrainingCompletedWomen: false,
            mainTrainingCompletedNextGen: false,
            mainTrainingCompletedWomen2: false,
            mainTrainingCompletedNextGen2: false,
            electiveTrainingCompleted: false,
            done: false,
        })
    }

    const mainTrainingCompletedCoaching = training.get("completedTrainings").filter((c) => { return c.get("webinarProgramId") === 62; }).size > 0;

    const certificationDesignation = progress.get("certificationDefinition").get("designation")
    const mainTrainingCompletedWomen = training.get("completedTrainings").filter((c) => { return c.get("webinarProgramId") === 64; }).size > 0;
    const mainTrainingCompletedWomen2 = training.get("completedTrainings").filter((c) => { return c.get("webinarProgramId") === 77; }).size > 0;
    const mainTrainingCompletedNextGen = training.get("completedTrainings").filter((c) => { return c.get("webinarProgramId") === 56; }).size > 0;
    const mainTrainingCompletedNextGen2 = training.get("completedTrainings").filter((c) => { return c.get("webinarProgramId") === 75; }).size > 0;
    const mainTrainingCompleted = certificationDesignation.includes("CAN")
        ? mainTrainingCompletedNextGen && mainTrainingCompletedNextGen2
        : mainTrainingCompletedWomen && mainTrainingCompletedWomen2;
    const mainTrainingsCompleted = mainTrainingCompleted && mainTrainingCompletedCoaching;

    const electiveTrainingCompleted = certificationDesignation.includes("CAN")
        ? training.get("completedTrainings").filter((c) => { return ![62, 56, 75].includes(c.get("webinarProgramId")) }).size > 0
        : training.get("completedTrainings").filter((c) => { return ![62, 64, 77].includes(c.get("webinarProgramId")) }).size > 0;

    const done = mainTrainingCompleted && mainTrainingCompletedCoaching //&& electiveTrainingCompleted;

    return ({
        mainTrainingsCompleted: mainTrainingsCompleted,
        mainTrainingCompletedCoaching: mainTrainingCompletedCoaching,
        mainTrainingCompletedWomen: mainTrainingCompletedWomen,
        mainTrainingCompletedNextGen: mainTrainingCompletedNextGen,
        mainTrainingCompletedWomen2: mainTrainingCompletedWomen2,
        mainTrainingCompletedNextGen2: mainTrainingCompletedNextGen2,
        electiveTrainingCompleted: electiveTrainingCompleted,
        done: done,
    })

}

const coaching = async (progress) => {

    const session = progress.get("details").get("sessionStep");

    let sessionAttended = session.get("conferenceId") !== null;

    let sessionRated = false;
    let sessionPassed = false;
    if (session.get("sessionReviews").size > 0) {
        sessionRated = session.get("sessionReviews").filter((a) => { return a.get("passed") === null; }).size === 0;
        sessionPassed = session.get("sessionReviews").filter((a) => { return a.get("passed"); }).size > 0;
    }

    return ({
        sessionCompleted: sessionAttended,
        sessionRated: sessionRated,
        sessionPassed: sessionPassed,
        done: sessionPassed,
    })

}

const attestations = async (progress) => {

    // Note - surveys for peers and clients are out of 70 points. 70% of 70 = 49 points.
    const attestation = progress.get("details").get("attestationStep");

    let attestationPeerPass;
    let attestationPeerFail;
    if (attestation.get("completedSurveyTypeformValues").size > 0) {
        attestationPeerPass = attestation.get("completedSurveyTypeformValues").filter((a) => { return a.get("surveyId") === "XeBmERhQ" && a.get("score") >= 49; }).size > 0;
        attestationPeerFail = attestation.get("completedSurveyTypeformValues").filter((a) => { return a.get("surveyId") === "XeBmERhQ" && a.get("score") < 49; }).size > 0;
    }

    let attestationClientPass;
    let attestationClientFail;
    if (attestation.get("completedSurveyTypeformValues").size > 0) {
        attestationClientPass = attestation.get("completedSurveyTypeformValues").filter((a) => { return a.get("surveyId") === "KFInwqUi" && a.get("score") >= 49; }).size > 0;
        attestationClientFail = attestation.get("completedSurveyTypeformValues").filter((a) => { return a.get("surveyId") === "KFInwqUi" && a.get("score") < 49; }).size > 0;
    }

    const peerRedo = attestationPeerFail && !attestationPeerPass
    const clientRedo = attestationClientFail && !attestationClientPass

    const done = attestationPeerPass && attestationClientPass;

    return ({
        peerCompleted: attestationPeerPass,
        clientCompleted: attestationClientPass,
        peerRedo: peerRedo,
        clientRedo: clientRedo,
        done: done
    })

}
const profile = async (progress) => {

    const profile = progress.get("details").get("profileSetupStep");

    const profileCompleted = profile.get("isProfileCompleted");
    const calendarCompleted = true; //profile.get("isCalendarSynced");

    const done = profileCompleted && calendarCompleted;

    return ({
        calendarCompleted: calendarCompleted,
        profileCompleted: profileCompleted,
        done: done
    })

}

const mapCertificationProgress = async (progress) => {

    const trainingStep = await trainings(progress);
    const coachingStep = await coaching(progress);
    const attestationStep = await attestations(progress);
    const profileStep = await profile(progress);

    return {
        stepsCompleted: [trainingStep.done, coachingStep.done, attestationStep.done, profileStep.done],
        step1Pieces: trainingStep,
        step2Pieces: coachingStep,
        step3Pieces: attestationStep,
        step4Pieces: profileStep,
        certificationDefinition: progress.get("certificationDefinition").toJS()
    };

};

export const certificationValidation = () => {
    return async (dispatch, getState) => {

        const status = await dispatch(tryAuthOnly(null, false, true));

        const progress = getState().common.user.get("certificationProgress");
        const completed = getState().common.user.get("completedCertificates");

        return {
            currentProgress: progress === null ? null : await mapCertificationProgress(progress),
            completedCertifications: completed === null ? null : (completed.size === 0 ? null : completed.toJS())
        };

    };
};