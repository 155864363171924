import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import moment from "moment/moment";

/* Components */
import {images} from "../../../../common/components/images";
import {colors} from "../../../../common/components/colors";
import DirectoryForm from "../components/directoryform";
import AvailabilityRecurringStatus from "../../../settings/recurring/containers/recurringstatus";
import CertTrainingsNav from "../components/listnav";
import {FontBody24, FontSubtitle21, FontSubtitle24, FontSubtitle40} from "../../../../common/components/fonts";
import {ToolTipCreator} from "../../dashboard/components/tooltips";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {setToolTipUpdate} from "../../tooltip/store/tooltip";
import {ButtonPrimary} from "../../../../common/components/buttons";
import {CertBack} from "./previewevents";

const TopSegment = styled.div`
  background-image: url(${images.firmBackgroundTop});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  left: -60px;
  right: 0;
  top: -90px;
  bottom: 0;
  padding: 90px 60px 0 60px;
  width: 100%;
  @media screen and (max-width: 748px) {
    left: -30px;
    right: -30px;
    top: -30px;
    bottom: 0;
    padding: 30px 30px 0 30px;
  }
`;

const Subtitle = styled(FontBody24)`
  margin: 30px 0 0 0;
  min-height: 108px;
`;
const Icon = styled.div`
  background-image: url(${props => props.image});
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  align-self: center;
  justify-self: center;
`;

const Nav = styled.div`
  display: flex;
  flex-direction: row;

  ${props => props.active !== "true"} {
    border-bottom: 3px solid ${colors.action100};
    cursor: default;

    & div {
      font-weight: bold;
    }
  }

  ${props => props.active !== "false"} {
    border-bottom: none;
    cursor: pointer;
  }

  padding: 10px;
  width: fit-content;
  align-items: center;
  color: ${colors.primary100};

  ${Icon} {
    width: 25px;
    height: 25px;
    margin-left: 5px;
  }
`;

const NavRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 60px;
  row-gap: 30px;
  padding-bottom: 40px;
`;

CertProfile.defaultProps = {
    init: () => {},
    certified: {
        "currentProgress": {
            "step4Pieces": {
                "profileCompleted": false,
                "calendarCompleted": false
            },
        },
        "completedCertifications": null,
    }
}

export default function CertProfile(props) {
    const init = props.init;
    const completedCertifications = props.certified.completedCertifications;
    const profileCompleted = props.certified.currentProgress?.step4Pieces.profileCompleted;
    const calendarCompleted = props.certified.currentProgress?.step4Pieces.calendarCompleted;
    const [profile, setProfile] = useState(true);
    const [calendar, setCalendar] = useState(false);
    const [profComp, setProfComp] = useState(profileCompleted);
    const [calComp, setCalfComp] = useState(calendarCompleted);

    useEffect(() => {
        setProfComp(profileCompleted)
    }, [profileCompleted])

    useEffect(() => {
        setCalfComp(calendarCompleted)
    }, [calendarCompleted])

    const showToolTip = useSelector(state => state.enterprise.tooltip.get('showToolTip'));
    const toolTipPage = useSelector(state => state.enterprise.tooltip.get('toolTipPage'));

    const history = useHistory();
    const myRef = useRef(null);
    const handleProfile = () => {
        executeScroll();
        setProfile(true)
        setCalendar(false)
        history.push("/my-certificates/profile")
    }

    useEffect(() => {
        if (showToolTip && toolTipPage === 7 && myRef !== null) {
            executeScroll();
        }
        // init();
    }, [toolTipPage]);

    const executeScroll = () => myRef.current.scrollIntoView({behavior: "smooth", block: "start"})


    const handleCalendar = () => {
        executeScroll();
        setProfile(false)
        setCalendar(true)
        history.push("/my-certificates/profile/calendar")

    }

    const pushToCertStep3 = () => {
        history.push("/my-certificates?step=3")
    }

    const pushToFinish = () => {
        history.push("/");
    }


    return (
        <>
            {/*<TopSegment ref={myRef}>*/}
            {/*    <FontSubtitle40 spaced={true}>Advisor Certificates</FontSubtitle40>*/}
            {/*    <Subtitle>Complete certificates designed to help you better attract and serve Women, NextGen & Underrepresented clients.</Subtitle>*/}
            {/*    <CertTrainingsNav />*/}
            {/*</TopSegment>*/}
            {/*<NavRow>*/}
            {/*    <Nav onClick={handleProfile} active={profile.toString()} style={{*/}
            {/*        position: 'relative',*/}
            {/*        boxShadow: `${showToolTip && toolTipPage === 7 ? "100px 1000px 0 3000px rgba(1,1,1,0.5)" : "unset"}`*/}
            {/*    }}><FontSubtitle21>PROFILE</FontSubtitle21>*/}
            {/*        {toolTipPage === 7 &&*/}
            {/*            <ToolTipCreator*/}
            {/*                text={"lorem ipsum text about profile"}*/}
            {/*                show={true}*/}
            {/*                style={{top: "-100px", right: "-150px"}}*/}
            {/*                linkToBack={pushToCertStep3}*/}


            {/*            />}*/}
            {/*        {profComp*/}
            {/*            ? <Icon image={images.checkboxCheckedWhite}/>*/}
            {/*            : <Icon image={images.checkboxEmptyWhite}/>*/}
            {/*        }*/}
            {/*    </Nav>*/}
            {/*    <Nav onClick={handleCalendar} active={calendar.toString()} style={{*/}
            {/*        position: 'relative',*/}
            {/*        boxShadow: `${showToolTip && toolTipPage === 8 ? "0 1000px 0 3000px rgba(1,1,1,0.5)" : "unset"}`*/}
            {/*    }}><FontSubtitle21>CALENDAR</FontSubtitle21>*/}
            {/*        {toolTipPage === 8 &&*/}
            {/*            <ToolTipCreator*/}
            {/*                text={"lorem ipsum text about calendar"}*/}
            {/*                show={true}*/}
            {/*                style={{top: "-100px", right: "-100px"}}*/}
            {/*                linkToNext={pushToFinish}*/}
            {/*            />}*/}
            {/*        {calComp*/}
            {/*            ? <Icon image={images.checkboxCheckedWhite}/>*/}
            {/*            : <Icon image={images.checkboxEmptyWhite}/>*/}
            {/*        }*/}
            {/*    </Nav>*/}
            {/*</NavRow>*/}
            <FontBody24 style={{"justifyContent":"space-evenly","marginBottom":"2em"}}><CertBack to={"/my-certificates"}><img src={images.chevronLeft} alt={"<"} /> Back</CertBack></FontBody24>
            {profile && <DirectoryForm onSave={init} completedCertifications={completedCertifications}/>}
            {calendar && <AvailabilityRecurringStatus onDisconnect={init}/>}
        </>
    )

}