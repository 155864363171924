import React from "react";
import moment from "moment/moment";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {Pagination} from "swiper";
import styled from "styled-components";
// import { useHistory } from "react-router-dom";

/* Components */
import {Swiper, SwiperSlide} from "swiper/react";
import {images} from "../../../../common/components/images";
import {FontBody18, FontSubtitle21} from "../../../../common/components/fonts";
// import { ButtonPrimary } from "../../../../common/components/buttons";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const WidgetContent = styled.div`
  margin-top: 20px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  & {
    --swiper-pagination-color: black;
  }
`;

const CenteredFontSubtitle21 = styled(FontSubtitle21)`
  text-align: center;
`;

const SwiperContainer = styled.div`
  .swiperslide {
    width: 100%;
    max-width: 100%;
    //@media screen and (max-width: 1088px) {
    //  max-width: 80%;
    //}
    justify-content: center;
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 100%;
    position: relative;
    padding-bottom: 30px;
  }
`;

const SlideContainer = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > * {
    margin-top: 0.5rem;
  }

  ${({ active }) =>
    !active &&
    `{
        opacity: 0.5;
    }`}

  transition: all 0.8s;
`;
const CertifiedBadge = styled.img`
  width: 200px;
  height: 200px;
  align-self: center;
`;
const CenteredFontBody18 = styled(FontBody18)`
  text-align: center;
`;
// const Cursor = styled.div`
//   cursor: pointer;
//   display: flex;
//   flex-direction: row;
// `;

const CompletedCertifications = (props) => {
    // const history = useHistory();

    // const handleRenew = (certification) => {
    //     console.log(certification);
    //     alert("Not implemented!");
    //     history.push("/my-certificates/renew");
    // };

    if (!props.completedCertifications || props.completedCertifications.length === 0) {
        return (
            <WidgetContent>
                <CenteredFontSubtitle21>
                    You don't have completed certificates.
                </CenteredFontSubtitle21>
            </WidgetContent>
        );
    }

    return (
        <WidgetContent>
            <SwiperContainer>
                <Swiper
                    className="certificationSwiper"
                    modules={[Pagination]}
                    pagination={{ clickable: false }}
                    noSwiping={false}
                    spaceBetween={5}
                    slidesPerView={"auto"}
                    loop={false}
                    grabCursor={true}
                    centeredSlides={true}
                >
                    {props.completedCertifications.map((certification, index) => (
                        <SwiperSlide key={index} className="swiperslide">
                            {({ isActive, isPrev, isNext }) => (
                                <SlideContainer
                                    active={isActive}
                                    isPrev={isPrev}
                                    isNext={isNext}
                                >

                                    <CertifiedBadge
                                        src={certification.designation.includes("CAN")
                                            ? images.nextGenBadge
                                            : images.womenBadge
                                        }
                                        alt={certification.name +" Badge"}
                                    />

                                    {/*<CenteredFontSubtitle21 spaced={false}>{certification.name} ({certification.designation})</CenteredFontSubtitle21>*/}
                                    <CenteredFontSubtitle21 spaced={false}>{certification.name}</CenteredFontSubtitle21>

                                    <CenteredFontBody18>
                                        Completed on:{" "}
                                        {moment(certification.completedOn).format("MM/DD/YYYY")}
                                    </CenteredFontBody18>

                                    {/*<Cursor*/}
                                    {/*  onClick={() => {*/}
                                    {/*    handleRenew(certification);*/}
                                    {/*  }}*/}
                                    {/*>*/}
                                    {/*  <ButtonPrimary canSubmit={true} label={"Renew"} />*/}
                                    {/*</Cursor>*/}
                                </SlideContainer>
                            )}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </SwiperContainer>
        </WidgetContent>
    );
};

export default CompletedCertifications