import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';
import {v4 as uuid4} from 'uuid';
import ReactQuill from "react-quill";

/* Components */
import {FontTitle28, FontBody21, FontBody18} from '../../../../common/components/fonts';
import {colors} from '../../../../common/components/colors';
import {ButtonPrimary, ButtonTertiary} from '../../../../common/components/buttons';
import {images} from "../../../../common/components/images";
import {StyledModal} from "../../certification/containers/steplist";

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

const Preview = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 60px;
  row-gap: 30px;
  margin-bottom: 90px;
  border-bottom: 1px solid ${colors.backgroundColor2};
  padding-bottom: 90px;
  @media screen and (max-width: 748px) {
    flex-direction: column;
  }
`;
const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
`;
const TitleSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
`;
const Photo = styled.img`
  margin-top: 10px;
  width: 250px;
  height: 190px;
  object-fit: cover;
  border-radius: 30px;
`;
const Button = styled.div`
  & div {
    cursor: pointer;
    font-size: 18px;
  }
`;
const ButtonOff = styled.div`
  & div {
    cursor: default;
    font-size: 18px;
  }
`;
const SeeMore = styled(Link)`
  text-decoration: underline;
  color: ${colors.primary100};
  width: fit-content;
`;
const Description = styled.div`
  width: calc(100% - 310px);
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 1088px;
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
  @media screen and (max-width: 748px) {
    width: auto;
  }
`;
const QuillEdit = styled(FontBody18)`
  .ql-editor {
    padding: 0 !important;
    font-family: ${env.STYLES.FONTS.body.fontFamily};
    font-size: 18px;
    line-height: 24px;
  }
`;
const CECredit = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  & img {
    height: 32px;
    align-items: center;
    justify-content: flex-start;
  }
`;
const ButtonComplete = styled.div`
  & div {
    cursor: default;
  }
`;
const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;;
  align-items: center;
  margin-top: 20px;
`;

class FirmTrainingsPreviewEventsElectives extends React.Component {

    state = {
        "videoSelected": null,
        "showModal": false
    }

    clickNewVideo = async (id) => {
        // if(id !== 372 && this.props.validation.currentProgress !== null && !this.props.validation.currentProgress?.step1Pieces.mainTrainingsCompleted) {
        //     console.log("show modal")
        //     this.setState({"videoSelected": id, "showModal":true})
        // } else {
        //     console.log("no  modal");
            this.props.history.push("/my-tasks/course/" + id + "/video");
        // }
    }

    render() {
        return (
            <>
                <Preview key={this.props.index}>
                    <Photo src={this.props.t.imageUrl} alt={""}/>
                    <Description>
                        <TitleSection>
                            <TitleSubtitle>
                                <FontTitle28 spaced={false}>{this.props.t.title}</FontTitle28>
                                <div>
                                    {/*{!this.props.t.isPromotionalProgram &&*/}
                                    {/*    <FontBody21>{moment(this.props.t.startsOn).tz(this.props.timeZone).format("MMM D, YYYY [at] h:mm a") + " - " + moment(this.props.t.startsOn).add(50, "minutes").tz(this.props.timeZone).format("h:mm a z")}</FontBody21>*/}
                                    {/*}*/}
                                    {(this.props.t.ceCredits !== undefined && this.props.t.ceCredits !== null && this.props.t.ceCredits > 0) &&
                                        <CECredit>
                                            <img src={images.firmIconCECredit} alt={""}/>
                                            <FontBody21>{this.props.t.ceCredits} Credit{this.props.t.ceCredits === 1 ? "" : "s"}</FontBody21>
                                        </CECredit>
                                    }
                                </div>
                            </TitleSubtitle>
                            {this.props.t.hasCourse
                                && <>
                                    {this.props.t.isCourseCompleted
                                        ? <>{this.props.t.isQuizCompleted
                                            ? <ButtonComplete><ButtonTertiary canSubmit={false}
                                                                              label={"Training completed"}/></ButtonComplete>
                                            : <div onClick={() => {
                                                this.props.history.push("/my-tasks/course/" + this.props.t.quizId + "/quiz")
                                            }}><ButtonPrimary canSubmit={false} label={"Take quiz"}/></div>
                                        }</>
                                        : <>
                                            {this.props.t.isCourseAssigned
                                                ? <div onClick={() => {
                                                    this.props.history.push("/my-tasks/course/" + this.props.t.courseId + "/video")
                                                }}><ButtonPrimary canSubmit={false} label={"Continue video"}/>
                                                </div>
                                                : <div onClick={() => {console.log("nonreq"); this.clickNewVideo(this.props.t.courseId);} }><ButtonPrimary canSubmit={false}
                                                                                                                                                label={"Watch video & take quiz"}/></div>
                                            }
                                        </>
                                    }
                                {/*</>*/}
                                {/*: <>*/}
                                {/*    {this.props.t.isPromotionalProgram*/}
                                {/*        ? <Button onClick={() => {*/}
                                {/*            this.props.history.push("/my-trainings/" + this.props.t.id + "/requested")*/}
                                {/*        }}><ButtonPrimary canSubmit={false} label={"Request"}/></Button>*/}
                                {/*        : <>*/}
                                {/*            {this.props.t.canRegister*/}
                                {/*                ? <Button onClick={() => {*/}
                                {/*                    this.props.history.push("/my-trainings/" + this.props.t.id + "/register")*/}
                                {/*                }}><ButtonPrimary canSubmit={false}*/}
                                {/*                                  label={"Register"}/></Button>*/}
                                {/*                : <ButtonOff><ButtonTertiary canSubmit={false}*/}
                                {/*                                             label={"Registered"}/></ButtonOff>*/}
                                {/*            }*/}
                                {/*        </>*/}
                                {/*    }*/}
                                </>
                            }
                        </TitleSection>
                        <QuillEdit>
                            <ReactQuill
                                id={uuid4()}
                                readOnly={true}
                                theme={null}
                                value={this.props.t.description.substr(0, 200) + (this.props.t.description.length > 200 ? "..." : "")}
                                bounds={'.app'}
                            />
                        </QuillEdit>
                        <SeeMore to={"/elective-trainings/" + this.props.t.id}><FontBody21>See more</FontBody21></SeeMore>
                    </Description>
                </Preview>
                {this.state.showModal &&
                    <StyledModal onClick={(e) => e.stopPropagation()} style={{zIndex:"100"}}>
                        <FontBody18>You are about to take an elective training that will not count towards your certificate - would you like to continue?</FontBody18>
                        <SpaceBetween>
                            <div onClick={() => this.setState({"showModal":false})}> <ButtonTertiary canSubmit={true} label={"Cancel"}/>
                            </div>
                            <div onClick={() => this.props.history.push("/my-tasks/course/" + this.state.videoSelected + "/video")}> <ButtonPrimary canSubmit={true}
                                                                                                                                                    label={"Continue"}/></div>
                        </SpaceBetween>
                    </StyledModal>
                }
            </>
        )
    }
}

export default withRouter(FirmTrainingsPreviewEventsElectives);
