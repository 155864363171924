import React, {useEffect, useState} from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from "styled-components";
import moment from "moment";
import {Map, List} from 'immutable';

/* Components */
import { colors } from "../../../../common/components/colors";
import {ADVISOR_FOR_WOMEN_CERT_ID, ADVISOR_FOR_NEXTGEN_CERT_ID} from "../../../../common/constants";
import { FontBody24, FontSubtitle40 } from "../../../../common/components/fonts";

/* Containers */
import FirmPlanWomen from "./firmplanwomen";
import FirmPlanNextGen from "./firmplannextgen";
import FirmPlanClientAcquisition from "./firmplanclientacquisition";

/* Store */
import { setSupportOpen } from "../../../support/store/support";
import { useSelector } from "react-redux";

const Subtitle = styled(FontBody24)`
    margin: 30px 0 0 0;
    min-height: 108px;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;
const Upgrade = styled.div`
    display: inline-block;
    text-decoration: underline;
    font-weight: bold;
    color: ${colors.primary100};
    cursor: pointer;
`;

export default function FirmPlansGrid() {
    const [certWomen, setWomen] = useState({
        status: "",
        renewalDate: "null",
        isExpired: false
    });
    const [certNextGen, setNextGen] = useState({
        status: "",
        renewalDate: "",
        isExpired: false
    });
    const [clientAcq, setClientAcq] = useState({
        status: "",
        renewalDate: "",
        isExpired: false
    });
    const [isLoading, setLoading] = useState(false);

    const wmcEmployeeId = useSelector((state) => state.common.user.get("wmcEmployeeId"));
    const isReferredByPartner = useSelector((state) => state.common.user.get("isReferredByPartner"));
    const isReferredByPartnerWMC = useSelector((state) => state.common.wmc.getIn(["wmc", "isReferredByPartner"]));
    const status = useSelector((state) => ({
        progress: state.common.user.get("certificationProgress"),
        completed: state.common.user.get("completedCertificates"),
        certPurchases: state.common.user.get("certificationPurchases"),
        renewals: state.common.user.get("directoryListings")
    }));

    const contactUs = () => {

    }

    useEffect(() => {
        init()
    },[])
    const init = () => {

        if (status.certPurchases !== List()) {
            let womenCertPurchases = status.certPurchases.filter(c => {
                return (c.get("certificationDefinitionId") === ADVISOR_FOR_WOMEN_CERT_ID)
            })
            if (womenCertPurchases.size > 0) {
                let womenCertPurchasesSorted = womenCertPurchases.sort((a, b) => {
                    return (new Date(b.get("purchasedOn")) - new Date(a.get("purchasedOn")))
                });
                let womenCertPurchasedExpiration = moment(womenCertPurchasesSorted.get(0).get("purchasedOn")).add(1, "years");
                if (moment().isAfter(womenCertPurchasedExpiration)) {
                    setWomen(certWomen => ({
                        ...certWomen,
                        isExpired: true
                    }))
                }
                certWomen.renewalDate = womenCertPurchasedExpiration.format("MM/DD/YYYY");
            }
            let nextGenCertPurchases = status.certPurchases.filter(c => {
                return (c.get("certificationDefinitionId") === ADVISOR_FOR_WOMEN_CERT_ID)
            })
            if (nextGenCertPurchases.size > 0) {
                let nextGenCertPurchasesSorted = nextGenCertPurchases.sort((a, b) => {
                    return (new Date(b.get("purchasedOn")) - new Date(a.get("purchasedOn")))
                });
                let nextGenCertPurchasedExpiration = moment(nextGenCertPurchasesSorted.get(0).get("purchasedOn")).add(1, "years");
                if (moment().isAfter(nextGenCertPurchasedExpiration)) {
                    setNextGen(certNextGen => ({
                        ...certNextGen,
                        isExpired: true
                    }))
                }
                setNextGen(certNextGen => ({
                    ...certNextGen,
                    renewalDate: nextGenCertPurchasedExpiration.format("MM/DD/YYYY")
                }))
            }
        }
        if (status.renewals !== Map()) {
            if (status.renewals.get('clientAcquisitionUntil') !== null) { // clientAcq purchase
                setClientAcq(clientAcq => ({
                    ...clientAcq,
                    status: "complete"
                }))
                if (moment().isAfter(moment(status.renewals.clientAcquisitionUntil))) {
                    setClientAcq(clientAcq => ({
                        ...clientAcq,
                        isExpired: true
                    }))
                }
                setClientAcq(clientAcq => ({
                    ...clientAcq,
                    renewalDate: moment(status.renewals.get('clientAcquisitionUntil')).format("MM/DD/YYYY")
                }))
            }
        }
        if (status.completed.length !== 0) { // completed certifications
            status.completed.forEach((item, key) => {
                let certificateType = item.get('certificationDefinitionId');
                if (certificateType === ADVISOR_FOR_WOMEN_CERT_ID) {
                    setWomen(certWomen => ({
                        ...certWomen,
                        status: "completed"
                    }))
                }
                if (certificateType === ADVISOR_FOR_NEXTGEN_CERT_ID) {
                    setNextGen(certNextGen => ({
                        ...certNextGen,
                        status: "completed"
                    }))
                }
            });
        }
        if (status.progress !== null) { // in progress certifications
            let certificationProgress = status.progress.get('certificationDefinition');
            if (certificationProgress.get('id') === ADVISOR_FOR_WOMEN_CERT_ID && certWomen.status !== "completed") {
                setWomen(certWomen => ({
                    ...certWomen,
                    status: "inProgress"
                }))
            }
            if (certificationProgress.get('id') === ADVISOR_FOR_NEXTGEN_CERT_ID && certNextGen.status !== "completed") {
                setNextGen(certNextGen => ({
                    ...certNextGen,
                    status: "inProgress"
                }))
            }
        }



    }

    if (isLoading) {
        console.log("loading")
        return null
    } else {
        return (
            <>
                <FontSubtitle40 spaced={true}>CHOOSE A PLAN</FontSubtitle40>
                <Subtitle>Looking to deploy Willow across your advisory firm? Please contact our enterprise sales team. <Upgrade onClick={contactUs}><b>Contact us</b></Upgrade>.</Subtitle>
                <FlexRow>
                    <FirmPlanWomen certWomen={certWomen} certNextGen={certNextGen} />
                    <FirmPlanNextGen certNextGen={certNextGen} certWomen={certWomen} />
                    <FirmPlanClientAcquisition clientAcquisition={clientAcq} />
                </FlexRow>
            </>
        )
    }

}

// const mapDispatchToProps = dispatch => ({
//     setSupportOpen: (isOpen, page) => dispatch(setSupportOpen(isOpen, page)),
// })
//
// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmPlansGrid));
