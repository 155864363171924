import React from 'react';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';

/* Components */
import {colors} from '../../../../common/components/colors';
import {FontBody24, FontSubtitle40} from '../../../../common/components/fonts';
import {images} from "../../../../common/components/images";
import FirmMarketingNav from '../components/nav';

/* Containers */
// import FirmMarketingOverview from "./overview";
// import FirmMarketingProfile from "./profile";
// import FirmMarketingCompliance from "./compliance";
import FirmMarketingProspects from "./prospects";
import FirmMarketingCriteria from "./criteria";

export const TopSegment = styled.div`
    background-image: url(${images.firmBackgroundTop});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    left: -60px;
    right: 0;
    top: -90px;
    bottom: 0;
    padding: 90px 60px 0 60px;
    width: 100%;
    @media screen and (max-width: 748px) {
        left: -30px;
        right: -30px;
        top: -30px;
        bottom: 0;
        padding: 30px 30px 0 30px;
    }
    @media screen and (max-width: 450px) {
        padding: 30px 15px 0 30px;
    }
`;
const Subtitle = styled(FontBody24)`
    margin: 30px 0 0 0;
    min-height: 108px;
`;
const Upgrade = styled.div`
    display: inline-block;
    text-decoration: underline;
    font-weight: bold;
    color: ${colors.primary100};
    cursor: pointer;
`;
const Foreground = styled.div`    
    position: relative;
    z-index: 2;
`;
const Background = styled.div`    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.white};
    z-index: 1;
`;

class FirmMarketingStructure extends React.Component {
    render() {
        return (
            <>
                <Foreground>
                    <TopSegment>
                        <FontSubtitle40 spaced={true}>CLIENT ACQUISITION</FontSubtitle40>
                        {/*{this.state.cannotAccessAllTrainings*/}
                        {/*    ? <Subtitle>TBD. You have [{this.props.pms_sessions}] TBD. <Upgrade onClick={this.addMoreTrainings}><b>Add more TBD.</b></Upgrade></Subtitle>*/}
                        {/*    : */}
                        <Subtitle>Converting prospects to clients with Willow Client Acquisition.</Subtitle>
                        {/*}*/}
                        <FirmMarketingNav />
                    </TopSegment>
                    {/*{(location.pathname === "/my-growth") && <FirmMarketingOverview />}*/}
                    {(location.pathname === "/my-growth/profile") && <FirmMarketingCriteria />}
                    {/*{(location.pathname === "/my-growth/articles") && <FirmMarketingCompliance />}*/}
                    {(location.pathname === "/my-growth") && <FirmMarketingProspects />}
                </Foreground>
                <Background />
            </>
        )
    }
}

export default withRouter(FirmMarketingStructure);